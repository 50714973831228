import React, { useCallback, useContext, useState } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { SnackbarContext, SnackbarVariant } from '@eas/common-web';

import { DefaultDialog } from '../../../../components/dialog/vpuk-dialog/vpuk-default-dialog';
import { DialogContentProps } from '../../../../components/dialog/types';
import { fetchBlockCard } from './actions-api';
import { CardContext } from '../../../../components/card-context/card-context';
import { FormattedMessage, useIntl } from 'react-intl';

async function blockCard(cardId: number) {
  try {
    await fetchBlockCard(cardId);
    return { ok: true };
  } catch (err) {
    return {
      ok: false,
      data: err,
    };
  }
}

interface Values {
  cardId: number;
}

export function BlockCardDialogContent({
  onClose,
  initialValues,
}: DialogContentProps<Values>) {
  const { showSnackbar } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const cardId = initialValues?.cardId;
  const { modifyCard } = useContext(CardContext);
  const intl = useIntl();

  const handleConfirm = useCallback(async () => {
    if (cardId === undefined) {
      onClose();
      return;
    }

    setLoading(true);
    const response = await blockCard(cardId);
    setLoading(false);

    if (response.ok) {
      showSnackbar(
        intl.formatMessage({ id: 'VPUK__BLOCK_DIALOG__SUCCESSFULLY_BLOCKED' }),
        SnackbarVariant.BLANK
      );
      modifyCard(cardId, { typeOfHolder: 'Z' });
    } else {
      showSnackbar(
        intl.formatMessage({ id: 'VPUK__BLOCK_DIALOG__FAILED_TO_BLOCK' }),
        SnackbarVariant.ERROR
      );
    }

    onClose();
  }, [cardId, onClose, showSnackbar, intl, modifyCard]);

  return (
    <DefaultDialog
      title={intl.formatMessage({ id: 'VPUK__BLOCK_DIALOG__BLOCK_CARD' })}
      actions={
        <>
          <Button color="primary" onClick={handleConfirm} disabled={loading}>
            Potvrdit
          </Button>
        </>
      }
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <Typography>
          <FormattedMessage id="VPUK__BLOCK_DIALOG__BLOCK_CARD_CONFIRM" />
        </Typography>
      )}
    </DefaultDialog>
  );
}
