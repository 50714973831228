import { Backend } from '../../../../api/endpoints';

export const fetchPaymentStatus = async (
  paymentNumber: number
): Promise<Backend.PaymentStatusDto> => {
  const res = await fetch(
    `/api/prukazy/auth/payment/status?paymentNumber=${paymentNumber}`,
    {
      method: 'GET',
      headers: new Headers({ 'Content-Type': 'application/json' }),
    }
  );

  return res.json();
};

export const resolvePayment = async (paymentNumber: string) => {
  return await fetch(
    `/api/prukazy/auth/card/purchased/resolve?paymentNumber=${paymentNumber}`,
    {
      method: 'PUT',
    }
  );
};
