import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import { App } from './modules/app';
import { initHotreload } from '@eas/common-web';

if (process.env.NODE_ENV === 'development') {
  initHotreload('ws://localhost:8090');

  // set user for dev purposes (add header token for every fetch)
  const MOCK_USER_ID = '70044084';
  const originalFetch = window.fetch;
  window.fetch = async (input, init = {}) => {
    const headers = new Headers(init.headers);
    headers.set('cuniPersonalID', MOCK_USER_ID);

    return originalFetch(input, {
      ...init,
      headers,
    });
  };
}

render(<App />, document.getElementById('app'));
