import React, { useContext } from 'react';

import { UserContext, LocaleName } from '@eas/common-web';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Redirect } from 'react-router-dom';

import vpukLogo from '../assets/logo-uk-color.png';
import vpukLogoEn from '../assets/logo-uk-color-en.png';
import { FormattedMessage, useIntl } from 'react-intl';
import { LanguageSwitcher } from '../components/header/language-switcher';

const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 34,
    marginBottom: 25,
    maxWidth: 500,
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    maxWidth: 275,
    height: 'auto',
    marginBottom: 20,
  },
  loginButton: {
    marginTop: '1rem',
    width: 220,
    fontSize: 16,
    padding: '14px 0',
    textTransform: 'none',
  },
  languageSwitcher: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

export function Login() {
  const classes = useStyles();
  const { isLogedIn } = useContext(UserContext);
  const { locale } = useIntl();

  /* login function for production */
  const login = async () => {
    window.location.href = window.location.origin + '/secure';
  };

  if (isLogedIn()) {
    return <Redirect to="/portal/prukazy" />;
  }

  return (
    <div className={classes.page}>
      <div className={classes.loginContainer}>
        <div className={classes.languageSwitcher}>
          <LanguageSwitcher />
        </div>
        <img
          src={locale === LocaleName.cs ? vpukLogo : vpukLogoEn}
          className={classes.logo}
        />
        <Typography component="h1" className={classes.title} align="center">
          <FormattedMessage id="VPUK__LOGIN__TITLE" />
        </Typography>
        <Button
          onClick={login}
          className={classes.loginButton}
          color="primary"
          variant="contained"
          size="large"
        >
          <FormattedMessage id="VPUK__LOGIN__BUTTON" />
        </Button>
      </div>
    </div>
  );
}
