import { Backend } from '../../api/endpoints';
import { CardStylesProps } from './types';
import ALIVE from './assets/ALIVE.png';
import CERVENA from './assets/CERVENA.png';
import KLIC from './assets/KLIC.png';
import MODRA from './assets/CERVENA.png';
import ORANZOVA from './assets/ORANZOVA.png';
import STUD_ALIVE from './assets/STUD_ALIVE.png';
import STUD_ISIC from './assets/STUD_ISIC.png';
import ZAME_ITIC from './assets/ZAME_ITIC.png';
import ZELENA from './assets/ZELENA.png';

type CardInfo = { cardPath: string | null; cardStyles?: CardStylesProps };

export const getCardStyles = (
  cardType?: Backend.CardType,
  licenceType?: Backend.LicenceType
): CardInfo => {
  switch (licenceType) {
    case 'ALIVE':
      if (cardType === 'STUD') {
        return {
          cardPath: STUD_ALIVE,
          cardStyles: {
            fontSize: 9,
            image: {
              top: 15,
              right: 10,
            },
            name: {
              left: 14,
              bottom: 56,
            },
            validTo: {
              left: 87,
              bottom: 40,
            },
            birthDate: {
              left: 14,
              bottom: 40,
            },
            personNumber: {
              display: 'none',
            },
          },
        };
      } else {
        return {
          cardPath: ALIVE,
          cardStyles: {
            fontSize: 9,
            image: {
              top: 15,
              right: 10,
            },
            name: {
              left: 14,
              bottom: 56,
            },
            validTo: {
              left: 87,
              bottom: 40,
            },
            birthDate: {
              left: 14,
              bottom: 40,
            },
            personNumber: {
              display: 'none',
            },
          },
        };
      }
    case 'ISIC':
      return {
        cardPath: STUD_ISIC,
      };
    case 'ITIC':
      return {
        cardPath: ZAME_ITIC,
        cardStyles: {
          fontSize: 9,
          name: {
            bottom: 57,
          },
          birthDate: {
            bottom: 40,
          },
          personNumber: {
            display: 'none',
          },
          validTo: {
            left: 87,
            bottom: 40,
          },
        },
      };
  }
  switch (cardType) {
    case 'EUSA':
    case 'EUSP':
    case 'FKUK':
    case 'EXT':
      return {
        cardPath: CERVENA,
        cardStyles: {
          name: {
            bottom: 45,
            left: 11,
          },
          validTo: {
            display: 'none',
          },
          birthDate: {
            bottom: 71,
            left: 11,
          },
          personNumber: {
            display: 'none',
          },
        },
      };

    case 'KLIC':
      return {
        cardPath: KLIC,
        cardStyles: {
          name: {
            display: 'none',
          },
          validTo: {
            display: 'none',
          },
          birthDate: {
            display: 'none',
          },
          personNumber: {
            display: 'none',
          },
        },
      };

    case 'ALUM':
    case 'CZV':
      return {
        cardPath: MODRA,
        cardStyles: {
          image: {
            top: 27,
            right: 8,
          },
          name: {
            bottom: 45,
            left: 11,
          },
          validTo: {
            display: 'none',
          },
          birthDate: {
            bottom: 71,
            left: 11,
          },
          personNumber: {
            display: 'none',
          },
          barcode: {
            display: 'block',
          },
        },
      };

    case 'ZAME':
      return {
        cardPath: ORANZOVA,
        cardStyles: {
          name: {
            bottom: 45,
            left: 11,
          },
          image: {
            top: 28,
            right: 9,
          },
          validTo: {
            display: 'none',
          },
          birthDate: {
            left: 11,
            bottom: 70,
          },
          personNumber: {
            display: 'none',
          },
          barcode: {
            display: 'block',
          },
        },
      };

    case 'STAZ':
    case 'STUD':
      return {
        cardPath: ZELENA,
        cardStyles: {
          image: {
            top: 22,
            right: 13,
          },
          name: {
            bottom: 58,
            left: 7,
          },
          validTo: {
            bottom: 7,
          },
          birthDate: {
            bottom: 31,
          },
          personNumber: {
            bottom: 31,
            left: 98,
          },
        },
      };
  }

  return {
    cardPath: STUD_ISIC,
  };
};
